
import { computed } from "vue";
import { useIsExpired } from "@libraryComposables/useIsExpired";
import { useRoot } from "@libraryHelpers/compositionApi";
import { renderData } from "@libraryHelpers/dataComponents";
import { cloneDeep as _cloneDeep } from "lodash";

export default {
	name: "CoreBlockRichText",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const root = useRoot();
		const itemCheckFunction = (item) => item.type === "copy";
		const { isExpired } = useIsExpired(props.settings, itemCheckFunction);

		const replaceUrlBasePathInContent = (content) => {
			return renderData(
				content?.replace(
					/href=('|")[\/]/gi,
					`href="${root.$config["_app"].basePath}`
				) || "",
				getDataSource.value
			);
		};
		const getDataSource = computed(() => {
			if (props.settings.dataDictionaryIndex === null) {
				return props.dataSite;
			}
			// Component is part of a CoreBlockRepeat
			// Should render item of the list
			const key = props.settings.dataDictionaryKey;
			if (!key) {
				return props.dataSite;
			}
			const arr = props.dataSite.dataDictionary[key];
			if (!arr) {
				return props.dataSite;
			}
			const arrValue = arr[props.settings.dataDictionaryIndex];

			// This RichTextBox could be also using data from dataSite,
			// So I return an object with fields from both.
			// It is possible to have key collisions, in that case the array value wins.
			const copy = _cloneDeep(props.dataSite);

			// If it's a list of strings, it must be used with {{ value }},
			// (mustache.js doesn't allow {{}})
			if (typeof arrValue === "string") {
				return Object.assign(copy, {
					value: arrValue,
				});
			}

			// If it's a list of objects, it must be used directly with the field key.
			const out = Object.assign(copy, arrValue);
			return out;
		});

		const blockContent = computed(() => {
			return props.settings?.bodyText
				? replaceUrlBasePathInContent(props.settings.bodyText)
				: replaceUrlBasePathInContent(props.settings.richText);
		});

		return {
			isExpired,
			blockContent,
		};
	},
};
